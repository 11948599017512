<template>
  <section class="banners">
    <img :alt="image.alt" :key="image._id" :src="image.image" v-for="image in page.banners" />
    <div class="icons">
      <div>
        <span>
          <font-awesome-icon icon="swimmer" />
        </span>
        <span>
          <font-awesome-icon icon="biking" />
        </span>
        <span>
          <font-awesome-icon icon="running" />
        </span>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    page() {
      return this.$store.state.page
    }
  }
}
</script>

<style scoped>
.banners {
  padding-left: 3rem;
  padding-right: 3rem;
  position: relative;
}
.banners img {
  z-index: 1;
}
.icons {
  position: absolute;
  color: white;
  z-index: 2;
  bottom: 4rem;
  padding-left: 3rem;
  padding-right: 3rem;
  left: 0;
  font-size: 3rem;
  width: 100%;
}
.icons > div {
  max-width: 1650px;
  margin: auto;
  width: 94%;
  text-align: right;
}
.icons span {
  display: inline-block;
  width: 7rem;
  height: 5rem;
  background-color: rgba(50, 52, 145, 0.75);
  color: #fff;
  text-align: center;
  line-height: 5rem;
  clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
  margin: 0 -1rem;
  border-bottom: 5px solid #ed2229;
}
@media screen and (max-width: 16500px) {
  .banners {
    padding-left: 3%;
    padding-right: 3%;
  }
}

@media screen and (max-width: 1100px) {
  .icons {
    font-size: 2rem;
    bottom: 3rem;
  }
  .icons span {
    width: 6rem;
    height: 5rem;
    line-height: 5rem;
    margin: 0 -0.5rem;
  }
}

@media screen and (max-width: 650px) {
  .icons {
    font-size: 2rem;
    bottom: 2rem;
  }
  .icons span {
    width: 5rem;
    height: 4rem;
    line-height: 4rem;
    margin: 0 -0.5rem;
  }
}
</style>
