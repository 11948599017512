<template>
  <section class="content">
    <div class="inner">
      <h1>{{ page.heading }}</h1>
      <div class="columns" v-html="page.main_text"></div>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    page() {
      return this.$store.state.page
    }
  }
}
</script>

<style scoped>
.content {
  padding: 8rem 0;
  font-size: 1.8rem;
}

.content h1 {
  text-align: center;
}

.content h1::after {
  margin: auto;
  margin-top: 3rem;
}

.columns {
  columns: auto 2;
  column-gap: 7rem;
  line-height: 1.8;
}

@media screen and (max-width: 1100px) {
  .content {
    padding: 5rem 0;
  }
  .columns {
    columns: unset;
    column-gap: unset;
  }
}
</style>
