<template>
  <section class="content" v-if="page.committee_profiles">
    <div class="inner">
      <h1>Committee</h1>
      <div class="grid">
        <a :key="profile._id" @click.prevent="showCProfile(profile)" href v-for="profile in page.committee_profiles">
          <img :alt="profile.name" :src="profile.image" />
          <div class="caption">{{ profile.name }}</div>
        </a>
      </div>
    </div>

    <modal :scrollable="true" height="auto" name="cprofile" width="90%">
      <a @click.prevent="$modal.hide('cprofile')" class="modal-close" href>
        <font-awesome-icon icon="times" />
      </a>
      <div class="modal-grid content">
        <div>
          <h2>{{ profileToShow.name }}</h2>
          <div v-html="profileToShow.bio"></div>
        </div>
        <div class="images">
          <img :alt="profileToShow.name" :src="profileToShow.image" />
        </div>
      </div>
    </modal>
  </section>
</template>

<script>
export default {
  data: function() {
    return {
      profileToShow: {}
    }
  },
  computed: {
    page() {
      return this.$store.state.page
    }
  },
  methods: {
    showCProfile(profile) {
      this.profileToShow = profile
      this.$modal.show("cprofile")
    }
  }
}
</script>

<style scoped>
.content {
  padding: 5rem 0;
  font-size: 1.8rem;
  line-height: 1.8;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 3rem;
}
.modal-grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 5rem;
}
.images {
  padding-top: 9rem;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .modal-grid {
    display: table;
    width: 100%;
  }
  .modal-grid > * {
    float: left;
    width: 67%;
    margin-right: 3%;
  }
  .modal-grid > *:last-child {
    width: 30%;
    margin-right: 0;
  }
  .grid {
    display: table;
    width: 100%;
  }
  .grid > * {
    float: left;
    width: 22%;
    margin-right: 3%;
    margin-bottom: 5rem;
  }
}

.grid a {
  position: relative;
  line-height: 0;
}

.grid a img {
  transition: all 0.3s;
}

.caption {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(50, 52, 145, 0.6);
  font-family: "Fugaz One", sans-serif;
  text-transform: uppercase;
  line-height: 1.2;
  color: white;
  padding: 2rem;
  border-top: 5px solid white;
  transition: all 0.3s;
  text-align: center;
}

.grid a:hover .caption {
  background-color: #ed2229;
  padding: 3rem 2rem;
}

.grid a:hover img {
  filter: brightness(1.15);
}

.content h1::after {
  margin-top: 2rem;
}
@media screen and (max-width: 1100px) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
  .images {
    padding-top: 4.5rem;
  }
  .content {
    padding: 3rem 0;
  }
  .content h1::after {
    margin-top: 2rem;
  }
}
@media screen and (max-width: 850px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .modal-grid {
    grid-template-columns: 1fr;
    grid-gap: rem;
  }
  .images {
    padding-top: 0;
  }
}
@media screen and (max-width: 450px) {
  .grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
