<template>
  <section class="content">
    <div class="inner">
      <div class="grid">
        <template v-if="isNewsPage">
          <page-link :key="article.ItemID" :to="article.url" v-for="article in page.articles">
            <div class="heading">
              <img :src="article.image" />
              <div class="heading-text">{{article.name}}</div>
            </div>
            <div class="details">
              <p>{{article.summary}}</p>
            </div>
          </page-link>
        </template>

        <template v-if="isOffersPage">
          <a
            :href="article.url"
            :key="article._id"
            target="_blank"
            rel="noreferrer"
            v-for="article in page.offers"
          >
            <div class="heading">
              <img :src="article.image" />
              <div class="heading-text">{{article.name}}</div>
            </div>
            <div class="details">
              <p>{{article.description}}</p>
            </div>
          </a>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters } from "vuex"

export default {
  computed: {
    ...mapState(["page"]),
    ...mapGetters(["isNewsPage", "isOffersPage"]),
  },
}
</script>

<style scoped>
.content {
  padding: 5rem 0;
  font-size: 1.6rem;
  line-height: 1.8;
  padding-top: 0;
  margin-top: -3rem;
}
.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5rem;
}
.grid img {
  width: 100%;
}
.grid > * {
  background: #f5f5f5;
}
.heading {
  position: relative;
  line-height: 0;
}
.heading svg {
  margin-right: 1rem;
}
.heading-text {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(50, 52, 145, 0.8);
  font-family: "Fugaz One", sans-serif;
  text-transform: uppercase;
  line-height: 1.2;
  color: white;
  padding: 3rem;
  border-top: 5px solid white;
  transition: all 0.3s;
  font-size: 2.5rem;
  text-align: center;
}
.grid a:hover .heading-text {
  background-color: #ed2229;
  padding: 5rem 3rem;
}
.details {
  padding: 2rem 3rem;
  padding-bottom: 1rem;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .grid {
    display: table;
    width: 100%;
  }
  .grid > * {
    float: left;
    width: 30%;
    margin-right: 3%;
  }
}

@media screen and (max-width: 1100px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 4rem;
  }
}
@media screen and (max-width: 850px) {
  .grid {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 2rem;
  }
}
</style>
