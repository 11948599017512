<template>
  <div>
    <header-elem />
    <template v-if="isHomePage">
      <banners-elem />
      <home-elem />
      <join-elem />
    </template>
    <template v-else>
      <banners-inner-elem
        v-if="
          page.banners &&
            page.banners.length &&
            !isTriathlonPage &&
            !isArticle &&
            !isNewsPage &&
            !isOffersPage &&
            !isContactPage &&
            !isFaqsPage
        "
      />
      <content-elem v-if="!isTriathlonPage" />
      <triathlon-elem v-if="isTriathlonPage" />
    </template>
    <register-elem v-if="isTriathlonPage" />
    <news-elem v-if="isNewsPage || isOffersPage" />
    <training-elem v-if="isTrainingPage" />
    <profiles-elem v-if="isAboutPage" />
    <committee-profiles-elem v-if="isAboutPage" />
    <buy-elem v-if="isKitPage" />
    <map-elem v-if="isContactPage" />
    <sponsors-elem />
    <blocks-elem />
    <footer-elem />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex"

import api from "@/services/api"

import HeaderElem from "@/components/Header.vue"
import ContentElem from "@/components/Content.vue"
import BannersElem from "@/components/Banners.vue"
import BannersInnerElem from "@/components/BannersInner.vue"
import JoinElem from "@/components/Join.vue"
import SponsorsElem from "@/components/Sponsors.vue"
import FooterElem from "@/components/Footer.vue"
import BlocksElem from "@/components/Blocks.vue"
import HomeElem from "@/components/Home.vue"
import ProfilesElem from "@/components/Profiles.vue"
import CommitteeProfilesElem from "@/components/CommitteeProfiles.vue"
import BuyElem from "@/components/Buy.vue"
import TrainingElem from "@/components/Training.vue"
import NewsElem from "@/components/News.vue"
import MapElem from "@/components/Map.vue"
import RegisterElem from "@/components/Register.vue"
import TriathlonElem from "@/components/Triathlon.vue"

export default {
  name: "page",
  components: {
    HeaderElem,
    BannersElem,
    BannersInnerElem,
    ContentElem,
    JoinElem,
    SponsorsElem,
    FooterElem,
    BlocksElem,
    HomeElem,
    ProfilesElem,
    BuyElem,
    TrainingElem,
    NewsElem,
    MapElem,
    RegisterElem,
    TriathlonElem,
    CommitteeProfilesElem
  },
  data() {
    return {}
  },
  computed: {
    ...mapState(["page"]),
    ...mapGetters([
      "isHomePage",
      "isAboutPage",
      "isTriathlonPage",
      "isArticle",
      "isNewsPage",
      "isOffersPage",
      "isTrainingPage",
      "isKitPage",
      "isContactPage",
      "isFaqsPage"
    ])
  },
  metaInfo() {
    return {
      //title: this.page.title,
      title: "Bassetlaw Triathlon Club",
      meta: [
        { name: "description", content: this.page.meta_desc },
        { name: "keywords", content: this.page.meta_keywords }
      ]
    }
  },
  created: function() {
    var route = this.$route
    var url = route.params.url
    var promises = []
    var store = this.$store
    var state = this.$store.state

    if (!url) store.commit("setPage", {})

    if (route.name == "404") this.page = { main_text: "Sorry, this page cannot be found!" }

    if (route.name == "page-by-url") promises.push(api.getPageByURL(url))
    if (route.name == "root") promises.push(api.getHomePage())

    Promise.all(promises).then(vals => {
      var returnedpage = vals[0]
      store.commit("setPage", returnedpage)

      if (!returnedpage || !returnedpage.ItemID)
        store.commit("setPage", { main_text: "Sorry, this page cannot be found!" })

      if (!this.isHomePage && !this.isAboutPage && !this.isTriathlonPage)
        if (!this.page.banners || !this.page.banners.length)
          api.getPageByItemID(state.aboutID, "banners").then(about => this.$set(this.page, "banners", about.banners))

      if (this.isNewsPage)
        api
          .getPages("image,summary", "page.articles=@" + this.page.ItemID)
          .then(subs => this.$set(this.page, "articles", subs))
    })

    store.dispatch("getSettings")
    store.dispatch("getMainPages")
  }
}
</script>
