<template>
  <div>
    <a aria-label="Menu" @click.prevent="showMenu = !showMenu" class="mobile-menu" href id="mobile-menu-btn">
      <font-awesome-icon icon="bars" v-show="!showMenu" />
      <font-awesome-icon icon="times" v-show="showMenu" />
    </a>
    <nav :class="{ open: showMenu }">
      <ul>
        <li :key="menuPage.ItemID" v-for="menuPage in menuPages">
          <page-link :href="menuPage.url || '/'" :class="{ sel: menuPage.ItemID == page.ItemID }">
            {{ menuPage.name }}
            <span v-if="menuPage.subs && menuPage.subs.length" @click.prevent="toggleSubMenu($event)" class="down">
              <font-awesome-icon icon="chevron-down" />
              <font-awesome-icon icon="chevron-up" />
            </span>
          </page-link>
          <ul v-if="menuPage.subs && menuPage.subs.length">
            <li :key="sub.ItemID" v-for="sub in menuPage.subs">
              <page-link :href="menuPage.url">{{ menuPage.name }}</page-link>
            </li>
          </ul>
        </li>
        <li>
          <a href="https://membermojo.co.uk/bassetlawtriclub" rel="noreferrer" target="_blank">Join Us</a>
        </li>
      </ul>
    </nav>
    <div class="social" v-if="settings">
      <a aria-label="Facebook" :href="settings.facebook" rel="noreferrer" target="_blank" v-if="settings.facebook">
        <font-awesome-icon :icon="['fab', 'facebook']" class="fb" />
      </a>
      <a aria-label="Instagram" :href="settings.instagram" rel="noreferrer" target="_blank" v-if="settings.instagram">
        <font-awesome-icon :icon="['fab', 'instagram']" class="in" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      showMenu: false,
    }
  },
  computed: {
    settings() {
      return this.$store.state.settings
    },
    page() {
      return this.$store.state.page
    },
    menuPages() {
      return this.$store.state.menu_pages
    },
  },
  methods: {
    toggleSubMenu: function (event) {
      var li = event.srcElement.closest("li")
      li.classList.toggle("open")
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
nav {
  position: absolute;
  top: 0;
  right: 10rem;
  font-family: "Fugaz One", sans-serif;
  z-index: 4;
  background: white;
}

.social {
  position: absolute;
  top: 0.3rem;
  right: 0;
  font-size: 3rem;
}
.social a {
  margin-left: 1rem;
}
.in {
  color: rgb(151, 10, 151);
}
.fb {
  color: #3b579d;
}

nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 100%;
}

nav ul li {
  display: inline-block;
  position: relative;
  padding: 0 2.5rem;
}

nav a {
  display: block;
  color: #323491;
  font-size: 2rem;
  text-decoration: none;
  line-height: 5rem;
  transition: all 0.2s;
  text-transform: uppercase;
}

nav > ul > li:last-child > a {
  color: #ed2229;
}

nav a.sel {
  border-bottom: 3px solid #ed2229;
}

nav ul ul a {
  padding: 1rem 2rem;
  line-height: 1.5;
  text-transform: none;
  text-align: left;
}

nav a:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Hide Dropdowns by Default */
nav ul ul {
  display: none;
  position: absolute;
  top: 7rem; /* the height of the main nav */
  left: 0;
}

/* Fisrt Tier Dropdown */
nav ul ul li {
  width: auto;
  float: none;
  display: list-item;
  position: relative;
}

/* Second, Third and more Tiers	*/
nav ul ul ul li {
  position: relative;
  top: 7rem;
  left: 170px;
}
nav .down {
  display: none;
  padding: 0.8rem 1rem;
  padding-left: 1.5rem;
  top: -0.8rem;
  right: -1rem;
  position: relative;
}

nav .down .fa-chevron-up {
  display: none;
}

.mobile-menu {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 4rem;
  color: #323491;
  line-height: 1;
}

.mobile-menu > * {
  position: absolute;
  left: 0;
  top: 0;
  transition: all 0.2s;
}

.mobile-menu .fa-times {
  margin-left: 0.5rem;
}

@media screen and (min-width: 1051px) {
  /* Display Dropdowns on Hover */
  nav ul li:hover > ul {
    display: inherit;
  }
}

@media screen and (max-width: 1600px) {
  nav a {
    font-size: 1.6rem;
  }
  nav ul li {
    padding: 0 2rem;
  }
}

@media screen and (max-width: 1350px) {
  nav a {
    font-size: 1.4rem;
  }
  nav ul li {
    padding: 0 1.5rem;
  }
}

@media screen and (max-width: 1100px) {
  nav {
    position: absolute;
    top: 80px;
    right: 120%;
    width: 100%;
    transition: all 0.3s;
    z-index: 9999;
    min-height: calc(100vh - 80px);
    text-align: left;
  }

  nav .down {
    display: block;
    float: right;
  }
  nav.open {
    right: 0;
  }
  nav ul {
    padding-bottom: 2rem;
  }
  nav ul a {
    padding: 1rem;
    line-height: 1.8 !important;
    font-size: 2rem;
  }
  nav ul li {
    display: block;
  }
  nav ul ul {
    position: relative;
    top: 0;
    left: 2rem;
    width: calc(100% - 4rem);
    padding-bottom: 1rem;
  }
  nav ul li.open ul {
    display: block;
  }

  nav li.open .down .fa-chevron-up {
    display: block;
  }

  nav li.open .down .fa-chevron-down {
    display: none;
  }

  .mobile-menu {
    display: block;
    top: 0.5rem;
  }
}

@media screen and (max-width: 500px) {
  .mobile-menu {
    top: -0.5rem;
    font-size: 3.5rem;
  }
  nav {
    top: 55px;
    min-height: calc(100vh - 55px);
  }

  .social {
    top: -0.5rem;
    font-size: 2.5rem;
  }
}
</style>
