<template>
  <section class="banners">
    <div class="left">
      <slide-show ref="slideshow" v-if="page.banners && page.banners.length">
        <div :key="image._id" class="slide" v-for="image in page.banners">
          <img :alt="image.alt" :src="image.image" />
        </div>
      </slide-show>
      <div class="caption" v-html="page.banners_caption"></div>
    </div>
    <div class="right-upper" :style="{backgroundImage: 'url(' + page.sub_1_image + ')'}">
      <page-link :to="page.sub_1_link_url" class="overlay">
        <span class="overlay-content">
          <h2>{{ page.sub_1_title }}</h2>
          <span class="overlay-content-more">
            <span class="overlay-content-more-link">{{ page.sub_1_link_text }}</span>
          </span>
        </span>
      </page-link>
    </div>
    <div class="right-lower" :style="{backgroundImage: 'url(' + page.sub_2_image + ')'}">
      <page-link :to="page.sub_2_link_url" class="overlay">
        <span class="overlay-content">
          <h2>{{ page.sub_2_title }}</h2>
          <span class="overlay-content-more">
            <span class="overlay-content-more-link">{{ page.sub_2_link_text }}</span>
          </span>
        </span>
      </page-link>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    page() {
      return this.$store.state.page
    }
  }
}
</script>

<style scoped>
.banners {
  z-index: 1;
  line-height: 0;
  clear: both;
  position: relative;
  overflow: hidden;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 3rem;
  padding-left: 3rem;
  padding-right: 3rem;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .banners {
    display: table;
    width: 100%;
  }
  .banners > * {
    float: left;
    width: 22%;
    margin-right: 3%;
    height: 50rem;
  }
  .banners > *:first-child {
    width: 47%;
  }
}

.left {
  grid-column: 1 / 2;
  grid-row: 1 / 3;
  position: relative;
}
.right-upper {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  background-size: cover;
  position: relative;
}
.right-lower {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  background-size: cover;
  position: relative;
}
.banners .overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(50, 52, 145, 0.6);
  display: flex;
  align-items: center;
}

.overlay-content {
  display: block;
  text-align: center;
  width: 100%;
  color: white;
  line-height: 1.3;
  text-transform: uppercase;
  font-family: "Fugaz One", sans-serif;
}

.overlay h2 {
  font-size: 4.5rem;
  padding: 0 3rem;
  font-weight: normal;
}

.overlay-content-more {
  display: block;
  opacity: 0.7;
  font-size: 2.5rem;
}

.overlay:hover .overlay-content-more {
  opacity: 1;
}

.overlay-content-more-link {
  border-bottom: 3px solid white;
}

.banners .right-lower .overlay {
  background-color: rgba(192, 67, 71, 0.8);
}

.cycle-slideshow {
  position: relative;
}

.cycle-slideshow .slide {
  display: none;
}

.cycle-slideshow .slide:first-child {
  display: block;
}

.banners img {
  opacity: 1;
}

.slide {
  width: 100%;
}

.slide img {
  width: 100%;
}

.caption {
  position: absolute;
  left: 5%;
  bottom: 6%;
  width: 94%;
  font-size: 7rem;
  line-height: 1.2;
  z-index: 200;
  color: #fff;
  text-transform: uppercase;
  font-family: "Fugaz One", sans-serif;
  text-shadow: 0px 0px 8px rgba(102, 102, 102, 0.5);
}

.caption >>> span {
  white-space: nowrap;
  background: #bb9765;
  padding: 1rem 2rem;
  margin: 0.5rem 0;
  display: inline-block;
}

.caption div {
  margin-top: 3rem;
}

@media screen and (max-width: 1500px) {
  .overlay h2 {
    font-size: 3.5rem;
    padding: 0 2rem;
  }

  .overlay-content-more {
    font-size: 2rem;
  }
}

@media screen and (max-width: 1100px) {
  .banners {
    grid-template-columns: 1fr;
    grid-template-rows: unset;
    grid-gap: 2rem;
    padding: 0 2rem;
  }
  .left,
  .right-lower,
  .right-upper {
    grid-column: 1 / 2;
    grid-row: unset;
  }
  .right-lower,
  .right-upper,
  .overlay {
    min-height: 30rem;
  }
  .caption {
    font-size: 3rem;
  }
}
</style>
