<template>
  <section class="map">
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9533.728866573565!2d-1.1277121!3d53.3176141!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3a5438da301c0020!2sWorksop%20Leisure%20Centre!5e0!3m2!1sen!2suk!4v1580225055483!5m2!1sen!2suk"
      width="100%"
      height="500"
      frameborder="0"
      style="border:0;"
      allowfullscreen
      class="map"
    ></iframe>
  </section>
</template>

<script>
export default {}
</script>

<style scoped>
.map {
  line-height: 0;
}
@media screen and (max-width: 850px) {
  .map {
    height: 300px;
  }
}
</style>
