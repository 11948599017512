<template>
  <section class="content">
    <div class="inner">
      <div class="grid">
        <div v-if="isNewsPage">
          <h1>{{ page.name }}</h1>
        </div>

        <div v-html="page.main_text" v-if="!isArticle"></div>

        <div v-if="isArticle">
          <h1>{{ page.name }}</h1>

          <span v-html="page.main_text"></span>

          <p v-if="newsPage">
            <page-link :to="newsPage.url">Back to news</page-link>
          </p>
        </div>

        <div class="images" v-if="page.image || page.images || isContactPage">
          <div class="img" v-if="page.image">
            <img :alt="page.name" :src="page.image" />
          </div>
          <div :key="image._id" class="img" v-for="image in page.images">
            <img :alt="image.alt" :src="image.image" />
            <div class="caption" v-html="image.caption" v-if="image.caption"></div>
          </div>

          <contact-form v-if="isContactPage"></contact-form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import ContactForm from "./ContactForm.vue"
export default {
  computed: {
    ...mapState(["page", "newsPage"]),
    ...mapGetters(["isArticle", "isContactPage", "isNewsPage"]),
  },
  components: {
    ContactForm,
  },
}
</script>

<style scoped>
.content {
  padding: 5rem 0;
  font-size: 1.6rem;
  line-height: 1.8;
}

.content h1::after {
  margin-top: 2rem;
}
.grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 8rem;
}

.grid a {
  border-bottom: 1px solid #ccc;
  transition: border 0.2s;
}
.grid a:hover {
  border-bottom: 2px solid #ccc;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .grid {
    display: table;
    width: 100%;
  }
  .grid > * {
    float: left;
    width: 67%;
    margin-right: 3%;
  }
  .grid > *:last-child {
    width: 30%;
    margin-right: 0;
  }
}

.images img {
  width: 100%;
}

.img {
  position: relative;
  max-width: 523px;
  line-height: 0;
}

.caption {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(50, 52, 145, 0.8);
  font-family: "Fugaz One", sans-serif;
  text-transform: uppercase;
  line-height: 1.2;
  color: white;
  padding: 2rem;
  border-top: 5px solid white;
  text-align: center;
}

.images {
  padding-top: 7rem;
}

.content >>> ul {
  padding-left: 2rem;
  padding-bottom: 2rem;
}

@media screen and (max-width: 1100px) {
  .images {
    padding-top: 4.5rem;
  }
  .content {
    padding: 3rem 0;
  }
  .content h1::after {
    margin-top: 2rem;
  }
}
@media screen and (max-width: 850px) {
  .grid {
    grid-template-columns: 1fr;
    grid-gap: 3rem;
  }
  .images {
    padding-top: 0;
  }
}
</style>
