var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('header-elem'),(_vm.isHomePage)?[_c('banners-elem'),_c('home-elem'),_c('join-elem')]:[(
        _vm.page.banners &&
          _vm.page.banners.length &&
          !_vm.isTriathlonPage &&
          !_vm.isArticle &&
          !_vm.isNewsPage &&
          !_vm.isOffersPage &&
          !_vm.isContactPage &&
          !_vm.isFaqsPage
      )?_c('banners-inner-elem'):_vm._e(),(!_vm.isTriathlonPage)?_c('content-elem'):_vm._e(),(_vm.isTriathlonPage)?_c('triathlon-elem'):_vm._e()],(_vm.isTriathlonPage)?_c('register-elem'):_vm._e(),(_vm.isNewsPage || _vm.isOffersPage)?_c('news-elem'):_vm._e(),(_vm.isTrainingPage)?_c('training-elem'):_vm._e(),(_vm.isAboutPage)?_c('profiles-elem'):_vm._e(),(_vm.isAboutPage)?_c('committee-profiles-elem'):_vm._e(),(_vm.isKitPage)?_c('buy-elem'):_vm._e(),(_vm.isContactPage)?_c('map-elem'):_vm._e(),_c('sponsors-elem'),_c('blocks-elem'),_c('footer-elem')],2)
}
var staticRenderFns = []

export { render, staticRenderFns }