<template>
  <section class="blocks" v-if="settings">
    <div class="inner">
      <div class="grid">
        <page-link
          :key="block._id"
          :style="{ backgroundImage: 'url(' + block.image + ')' }"
          :to="block.url"
          v-for="block in settings.blocks"
        >
          <span class="link-overlay">
            <span class="link-wrap">
              <span class="link-title">{{ block.name }}</span>
              <span class="link-sub">{{ block.text }}</span>
            </span>
          </span>
        </page-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    settings() {
      return this.$store.state.settings
    }
  }
}
</script>

<style scoped>
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 3rem;
  margin-bottom: 8rem;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .grid {
    display: table;
    width: 100%;
  }
  .grid > * {
    float: left;
    width: 22%;
    margin-right: 3%;
    height: 40rem;
  }
}

.grid a {
  background-size: cover;
}

.link-wrap {
  width: 100%;
  text-align: center;
  text-shadow: 0px 0px 8px rgba(102, 102, 102, 0.5);
}
.link-wrap span {
  display: block;
}
.link-overlay {
  width: 100%;
  height: 100%;
  min-height: 40rem;
  background-color: rgba(50, 52, 145, 0.6);
  display: flex;
  align-items: center;
  transition: all 0.3s;
}
.grid a:hover .link-overlay {
  background-color: #ed2229;
}
.link-title {
  color: white;
  line-height: 1.1;
  font-size: 5rem;
  padding: 0 3rem;
  text-transform: uppercase;
  font-family: "Fugaz One", sans-serif;
}
.link-sub {
  color: white;
}
@media screen and (max-width: 1400px) {
  .grid {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 600px) {
  .grid {
    grid-template-columns: 1fr;
    margin-bottom: 5rem;
  }
  .link-overlay {
    min-height: 30rem;
  }
}
</style>
