<template>
  <section class="content" v-if="page.summer_title || page.winter_title">
    <div class="inner">
      <div class="grid">
        <div v-if="page.summer_title">
          <div class="heading">
            <img :src="page.summer_image" />
            <div class="heading-text">
              <font-awesome-icon icon="sun" />
              {{ page.summer_title }}
            </div>
          </div>
          <div class="details" v-html="page.summer_text"></div>
        </div>
        <div v-if="page.winter_title">
          <div class="heading">
            <img :src="page.winter_image" />
            <div class="heading-text">
              <font-awesome-icon icon="snowflake" />
              {{ page.winter_title }}
            </div>
          </div>
          <div class="details" v-html="page.winter_text"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex"

export default {
  computed: {
    ...mapState(["page"])
  }
}
</script>

<style scoped>
.content {
  padding: 5rem 0;
  font-size: 1.6rem;
  line-height: 1.8;
  padding-top: 0;
  margin-top: -3rem;
}
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5rem;
}
.grid img {
  width: 100%;
}
.grid > * {
  background: #f5f5f5;
}
.heading {
  position: relative;
  line-height: 0;
}
.heading svg {
  margin-right: 1rem;
}
.heading-text {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(50, 52, 145, 0.8);
  font-family: "Fugaz One", sans-serif;
  text-transform: uppercase;
  line-height: 1.2;
  color: white;
  padding: 3rem;
  border-top: 5px solid white;
  transition: all 0.3s;
  font-size: 2.5rem;
  text-align: center;
}
.details {
  padding: 3rem 3rem;
  padding-bottom: 2rem;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .grid {
    display: table;
    width: 100%;
  }
  .grid > * {
    float: left;
    width: 47%;
    margin-right: 3%;
  }
}

@media screen and (max-width: 850px) {
}
</style>
