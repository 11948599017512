<template>
  <div class="form">
    <div class="form-head">
      <h2>Send a message</h2>
    </div>
    <div class="form-body">
      <input class="txt" placeholder="Name" type="text" v-model="form.name" />
      <input class="txt" placeholder="Email" type="email" v-model="form.email" />
      <input class="txt" placeholder="Telephone" type="tel" v-model="form.tel" />
      <textarea class="txt" placeholder="Message" v-model="form.message"></textarea>
      <span class="success" v-show="emailsuccess">Thanks, your request has been sent!</span>
      <span class="error" v-show="emailerror">{{errormessage}}</span>
      <button @click="sendEmail">Submit</button>
    </div>
  </div>
</template>

<script>
import api from "@/services/api"

import { mapState, mapGetters } from "vuex"

export default {
  computed: {
    ...mapState(["page"]),
    ...mapGetters(["isHomePage"])
  },
  data: function() {
    return {
      emailsuccess: false,
      emailerror: false,
      errormessage: "",
      form: {},
      initial_values: { dropdowns_need_to_be_initialised: "" }
    }
  },
  created: function() {
    this.form = JSON.parse(JSON.stringify(this.initial_values))
  },
  methods: {
    sendEmail: function() {
      this.emailsuccess = false
      this.emailerror = false

      if (!this.form.name || !this.form.email || !this.form.message) {
        this.errormessage = "Please fill in all required fields, thanks!"
        this.emailerror = true
        return false
      }

      var content = `The following message has been sent from the website:<br /><br />`

      content += `Name: ${this.form.name}<br />
                  Email: ${this.form.email}<br />`

      if (this.form.tel) content += `Telephone: ${this.form.tel}<br />`
      content += `Message: ${this.form.message}<br />`

      api
        .sendEmail("Enquiry from the website", content)
        .then(() => {
          this.emailsuccess = true
          this.form = JSON.parse(JSON.stringify(this.initial_values))
        })
        .catch(() => {
          this.errormessage = "There has been an error submitting your message"
          this.emailerror = true
        })
    }
  }
}
</script>

<style scoped>
.form {
  background: #f5f5f5;
  padding: 2rem 3rem;
}
.txt {
  width: 100%;
  border: 0;
  padding: 1rem 2rem;
  margin-bottom: 1rem;
}
textarea.txt {
  height: 15rem;
}
button {
  border: 0;
  background: #ed2229;
  padding: 1rem 2rem;
  color: white;
  font-family: "Fugaz One", sans-serif;
  text-transform: uppercase;
  margin-bottom: 2rem;
  font-size: 1.5rem;
}
</style>
