<template>
  <section class="sponsors" v-if="settings">
    <div class="inner">
      <h3>{{ settings.sponsors_text }}</h3>
      <div class="logos">
        <a
          :aria-label="image.url"
          :href="image.url"
          :key="image._id"
          rel="noreferrer"
          target="_blank"
          v-for="image in settings.sponsors"
        >
          <img :alt="image.url" :src="image.image" />
        </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    settings() {
      return this.$store.state.settings
    },
  },
}
</script>

<style scoped>
.sponsors {
  padding: 8rem 0;
  padding-bottom: 6rem;
  text-align: center;
  color: white;
}
.sponsors h3 {
  font-family: "Fugaz One", sans-serif;
  font-weight: normal;
  color: rgb(168, 168, 168);
  text-transform: uppercase;
  font-size: 2rem;
  padding-bottom: 2rem;
}
.sponsors img {
  margin: 2.5rem 2%;
  height: 10rem;
}
@media screen and (max-width: 1400px) {
  .sponsors img {
    height: 9rem;
  }
}
@media screen and (max-width: 1100px) {
  .sponsors img {
    height: 8rem;
  }
}
@media screen and (max-width: 700px) {
  .logos {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .sponsors {
    padding-bottom: 4rem;
  }
  .sponsors img {
    margin: 1.5rem 2%;
  }
}
</style>
