<template>
  <header>
    <div class="inner">
      <page-link class="logo" to="/">
        <img alt="Bassetlaw Triathlon Club" src="../assets/logo.png" />
      </page-link>
      <navigation-elem></navigation-elem>
    </div>
  </header>
</template>

<script>
import NavigationElem from "@/components/Navigation.vue"

export default {
  components: {
    NavigationElem,
  },
}
</script>

<style scoped>
header {
  padding: 4rem 0;
  position: relative;
}

.logo {
  width: 25rem;
  display: inline-block;
}

@media screen and (max-width: 1250px) {
  .logo {
    width: 22rem;
  }
}

@media screen and (max-width: 1100px) {
  .logo {
    position: relative;
    left: 8rem;
  }
}

@media screen and (max-width: 500px) {
  .logo {
    width: 15rem;
    left: 12.5%;
    top: 0rem;
  }
  header {
    padding: 3rem 0;
  }
}
</style>
