<template>
  <section class="join" v-if="settings">
    <div class="inner">
      <a :href="settings.sign_url" target="_blank" rel="noreferrer" class="border">
        <h1>{{settings.sign_title}}</h1>
        <p>{{settings.sign_text}}</p>
      </a>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    settings() {
      return this.$store.state.settings
    },
  },
}
</script>

<style scoped>
.join {
  padding: 8rem 0;
  font-size: 1.8rem;
  background: #323491;
  text-align: center;
  color: white;
}
.join h1 {
  font-family: "Fugaz One", sans-serif;
  font-weight: normal;
}
.join p {
  margin: 0;
  padding: 0;
}
.border {
  display: block;
  border: 5px solid #ed2229;
  padding: 4rem;
}
</style>
